import { CookieSharp } from "@mui/icons-material";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";
import { backend_base_url } from "../configs/constant";
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // base url of backend API
    baseUrl: backend_base_url,
    // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: "auth/me",
        method: "GET",
      }),
    }),
    getUserKycDetails: builder.query({
      query: () => ({
        url: "user/currentkyc",
        methdod: "Get",
      }),
    }),
    createUserKyc: builder.mutation({
      query: (initialKyc) => ({
        url: "user/create-kyc",
        method: "POST",
        body: initialKyc,
      }),
    }),
    updateUserKyc: builder.mutation({
      query: (initialKyc) => ({
        url: "user/updatekyc",
        method: "POST",
        body: initialKyc,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (initialProfile) => ({
        url: "user/update",
        method: "POST",
        body: initialProfile,
      }),
    }),
    resetPassword: builder.mutation({
      query: (initialPasswords) => ({
        url: "auth/reset-password",
        method: "POST",
        body: initialPasswords,
      }),
    }),
  }),
});

export const isUnClaimed = async (userId) => {
  const res = await axios.get(backend_base_url + `nft/${userId}`);
  return res.data;
};

export const enableMint = async (status) => {
  const token = Cookies.get("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const res = await axios.post(
    backend_base_url + `user/enable`,
    { status },
    { headers }
  );
  return res.data;
};

export const updateWalletAddress = async (data) => {
  const token = Cookies.get("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  try {
    const response = await axios.post(
      backend_base_url + `user/updateWallet`,
      data,
      {
        headers,
      }
    );
    return response.data;
  } catch {
    return false;
  }
};

export const updateProfitPercent = async (data) => {
  const token = Cookies.get("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  try {
    const response = await axios.post(
      backend_base_url + `user/updateProfitPercent`,
      data,
      {
        headers,
      }
    );
    return response.data;
  } catch {
    return false;
  }
};

export const checkEnable = async () => {
  const res = await axios.post(backend_base_url + `user/checkenable`);
  return res.data;
};

export const purchasePackage = async (data) => {
  try {
    const res = await axios.post(
      backend_base_url + `user/addPackageHistory`,
      data
    );
    if (res.data.success) return true;
  } catch (err) {
    return false;
  }
};

const register = (username, email, full_name, password) => {
  return axios.post(backend_base_url + "auth/register", {
    username,
    email,
    full_name,
    password,
  });
};

const loginWithRefreshToken = (refreshToken) => {
  return axios
    .post(backend_base_url + "auth/refresh-token", {
      refreshToken,
    })
    .then((response) => {
      if (response.data.access_token) {
        Cookies.set("access_token", response.data.access_token);
      }

      if (response.data.refresh_token) {
        Cookies.set("refresh_token", response.data.refresh_token);
      }

      return {
        user: response.data.user,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      };
    });
};
const login = (email, password) => {
  return axios
    .post(backend_base_url + "auth/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
        Cookies.set("access_token", response.data.access_token);
        localStorage.setItem("email", email);
      }

      if (response.data.refresh_token) {
        Cookies.set("refresh_token", response.data.refresh_token);
      }
      return {
        user: response.data.user,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      };
    });
};

const googleLogin = () => {
  return axios
    .post(backend_base_url + "auth/google-login", {})
    .then((response) => {
      if (response.data.access_token) {
        Cookies.set("access_token", response.data.access_token);
      }

      return {
        user: response.data.user,
        accessToken: response.data.access_token,
      };
    });
};

const logout = () => {
  Cookies.remove("access_token");
  localStorage.removeItem("email");
  return axios.post(backend_base_url + "logout").then((response) => {
    return response.data;
  });
};

const AuthService = {
  register,
  loginWithRefreshToken,
  googleLogin,
  login,
  logout,
};

export const getPostData = async (url, query) => {
  const token = Cookies.get("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  var result = [];
  await axios
    .post(backend_base_url + url, query, { headers })
    .then((response) => {
      result = response.data;
      return response.data;
    });
  return result;
};

export const getCurrentKyc = async () => {
  return await getPostData("user/currentkyc");
};

export const {
  useGetUserDetailsQuery,
  useGetUserKycDetailsQuery,
  useCreateUserKycMutation,
  useUpdateUserKycMutation,
  useUpdateUserProfileMutation,
  useResetPasswordMutation,
} = apiSlice;

export default AuthService;
