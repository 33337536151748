import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useGetUserDetailsQuery } from "./services/api.slice";
import { setCredentials, loginWithRefreshToken } from "./slices/auth.slice";

// Material UI
import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";

// React Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
//check
// Helmet
import { Helmet, HelmetProvider } from "react-helmet-async";

// Modules
import IndividualNft from "Pages/Collections/Individualnft";
import MyWalletInfoContainer from "Pages/MyWallets/MyWalletInfoContainer";
import Deposit from "Pages/MyWallets/Deposit";
import PendingPoolContainer from "Pages/MyWallets/PendingPoolContainer";
import NftCardDetails from "components/NftCard/NftCardDetails/NftCardDetails";
import Layout from "components/Layout/Layout";
import useCustomTheme from "hooks/useCustomTheme";
import Auction from "Pages/Auction/Auction";
import Explore from "Pages/Explore/Explore";
import Favourites from "Pages/Favourites/Favourites";
import AppStore from "Pages/AppStore/AppStore";
import TrendingSellers from "Pages/TrendingSellers/TrendingSellers";
import Home from "Pages/Home/Home";
import SellersDetails from "Pages/SellerDetails/SellersDetails";
import UserProfile from "Pages/UserProfile/UserProfile";
import TrendingCreators from "Pages/TrendingCreators/TrendingCreators";
import ProfileInterface from "components/ProfileInterface/ProfileInterface";
import KYCInterface from "components/KYCInterface/KYCInterface";
import LanguageInterface from "components/LanguageInterface/LanguageInterface";
import ThemeInterface from "components/ThemeInterface/ThemeInterface";
import EditProfile from "components/ProfileInterface/EditProfile";
import KYCPending from "components/KYCInterface/KYCPending";
import KYCApproved from "components/KYCInterface/KYCApproved";
import TermsAndCondition from "Pages/Terms&Condition/TermsAndCondition";
import FAQ from "Pages/FAQ/FAQ";
import PrivacyPolicy from "Pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "Pages/ContactUs/ContactUs";
import CreateAssets from "Pages/CreateAssets/CreateAssets";
import UpdateAssets from "Pages/CreateAssets/UpdateAssets";
import DummyUserProfile from "Pages/DummyUser/DummyUserProfile";
import AuctionCardDetails from "components/Auction/AuctionCardDetails/AuctionCardDetails";
import CreatorsDetails from "Pages/CreatorsDetails/CreatorsDetails";
import AnimatedLoader from "Utils/AnimatedLoader/AnimatedLoader";

// CSS Styles
import "./App.css";
import SignUpPage from "Pages/Authentication/SignUpPage/SignUpPage";
import PlisioDeposit from "Pages/MyWallets/PlisioDeposit";
import AuthInterface from "Pages/Authentication/AuthInterface";
import SignInPage from "Pages/Authentication/SignInPage/SignInPage";
import VerifyEmailPage from "Pages/Authentication/VerifyEmailPage/VerifyEmailPage";
import SetPasswordPage from "Pages/Authentication/SetPasswordPage/SetPasswordPage";
import ChangePasswordInterface from "components/ChangePasswordInterface/ChangePasswordInterface";
import ChangeEmailInterface from "components/ChangeEmailInterface/ChangeEmailInterface";
import Collections from "Pages/Collections/Collections";
import CreateCollection from "Pages/Collections/CreateCollection";
import EditCollection from "Pages/Collections/EditCollection";
import SellComponent from "components/NftCard/NftCardDetails/SellComponent";
import Error404 from "Pages/Errors/404";
import NoResponse from "Pages/Errors/NoResponse";
import CreateCryptos from "Pages/MyWallets/CreateCrypto";
import Campaign from "Pages/Campaign/Campaign";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { customTheme } = useCustomTheme(darkMode);

  const [showApp, setShowApp] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShowApp(true); // renders the data when DOM is ready.
  }, []);

  // automatically authenticate user if token is found
  const { data } = useGetUserDetailsQuery("userDetails", {
    pollingInterval: 900000, // 15mins
  });

  useEffect(() => {
    if (data) dispatch(setCredentials(data));
    else {
      dispatch(loginWithRefreshToken());
    }
  }, [data, dispatch]);

  const handleDarkThemeSwitch = () => {
    localStorage.setItem("theme", "dark");
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    }
  };

  const handleLightThemeSwitch = () => {
    localStorage.setItem("theme", "light");
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    console.log(theme);
    if (theme === "dark") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  }, [customTheme]);

  return (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider
        maxSnack={3}
        classes={{ containerRoot: "z-alert" }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <HelmetProvider>
          <Helmet
            bodyAttributes={
              darkMode
                ? { style: "background-color: #040404" }
                : { style: "background-color: #ffffff" }
            }
          />

          <div
            style={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              height: "100vh",
            }}
          >
            {showApp ? (
              <Box bgcolor={darkMode ? "#040404" : "#ffffff"}>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Layout darkMode={darkMode}>
                          <Home darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/404"
                      element={<Error404 darkMode={darkMode} />}
                    />
                    <Route
                      path="/noResponse"
                      element={<NoResponse darkMode={darkMode} />}
                    />
                    <Route
                      path="/home"
                      element={
                        <Layout darkMode={darkMode}>
                          <Home darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/myWalletInfo"
                      element={
                        <Layout darkMode={darkMode}>
                          <MyWalletInfoContainer darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/deposit"
                      element={
                        <Layout darkMode={darkMode}>
                          <Deposit darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/plisio-deposit"
                      element={
                        <Layout darkMode={darkMode}>
                          <PlisioDeposit darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/create-crypto"
                      element={
                        <Layout darkMode={darkMode}>
                          <CreateCryptos darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/campaign"
                      element={
                        <Layout darkMode={darkMode}>
                          <Campaign />
                        </Layout>
                      }
                    />
                    {/* <Route
                      path="/pendingpool"
                      element={
                        <Layout darkMode={darkMode}>
                          <PendingPoolContainer darkMode={darkMode} />
                        </Layout>
                      }
                    /> */}

                    <Route
                      path="/authentication"
                      element={<AuthInterface darkMode={darkMode} />}
                    >
                      <Route
                        path="sign-up"
                        element={<SignUpPage darkMode={darkMode} />}
                      />
                      <Route
                        path="sign-in"
                        element={<SignInPage darkMode={darkMode} />}
                      />
                      <Route
                        path="verify-email"
                        element={<VerifyEmailPage darkMode={darkMode} />}
                      />
                      <Route
                        path="set-password"
                        element={<SetPasswordPage darkMode={darkMode} />}
                      />
                    </Route>
                    <Route
                      path="/explore"
                      element={
                        <Layout darkMode={darkMode}>
                          <Explore darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/auction"
                      element={
                        <Layout darkMode={darkMode}>
                          <Auction darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections"
                      element={
                        <Layout darkMode={darkMode}>
                          <Collections darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections/create"
                      element={
                        <Layout darkMode={darkMode}>
                          <CreateCollection darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections/edit/:collectionId"
                      element={
                        <Layout darkMode={darkMode}>
                          <EditCollection darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/explore/:collectionId/:tokenId"
                      element={
                        <Layout darkMode={darkMode}>
                          <NftCardDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/sell/:collectionId/:tokenId"
                      element={
                        <Layout darkMode={darkMode}>
                          <SellComponent darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/auction/:collectionId/:tokenId"
                      element={
                        <Layout darkMode={darkMode}>
                          <AuctionCardDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/favourites"
                      element={
                        <Layout darkMode={darkMode}>
                          <Favourites darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/app-store"
                      element={
                        <Layout darkMode={darkMode}>
                          <AppStore darkMode={darkMode} mode={"OFUSER"} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-creators/:uid/collection/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-sellers/:uid/collection/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user/dummy/collection/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <IndividualNft darkMode={darkMode} mode="INUSER" />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections/collection/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-sellers"
                      element={
                        <Layout darkMode={darkMode}>
                          <TrendingSellers darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-sellers/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <SellersDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user/dummy"
                      element={
                        <Layout darkMode={darkMode}>
                          <DummyUserProfile darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/create-asset"
                      element={
                        <Layout darkMode={darkMode}>
                          <CreateAssets darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/update-asset/:collectionId/:tokenId"
                      element={
                        <Layout darkMode={darkMode}>
                          <UpdateAssets darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <Layout darkMode={darkMode}>
                          <UserProfile darkMode={darkMode} />
                        </Layout>
                      }
                    >
                      <Route
                        path="user-profile"
                        element={<ProfileInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="edit-profile"
                        element={<EditProfile darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc"
                        element={<KYCInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc-pending"
                        element={<KYCPending darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc-approved"
                        element={<KYCApproved darkMode={darkMode} />}
                      />
                      <Route
                        path="language"
                        element={<LanguageInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="change-password"
                        element={
                          <ChangePasswordInterface darkMode={darkMode} />
                        }
                      />
                      <Route
                        path="change-email"
                        element={<ChangeEmailInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="theme"
                        element={
                          <ThemeInterface
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                            handleDarkThemeSwitch={handleDarkThemeSwitch}
                            handleLightThemeSwitch={handleLightThemeSwitch}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/trending-creators"
                      element={
                        <Layout darkMode={darkMode}>
                          <TrendingCreators darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-creators/:id"
                      element={
                        <Layout darkMode={darkMode}>
                          <CreatorsDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/privacy-policy"
                      element={
                        <Layout darkMode={darkMode}>
                          <PrivacyPolicy darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/terms-and-condition"
                      element={
                        <Layout darkMode={darkMode}>
                          <TermsAndCondition darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/frequently-asked-questions"
                      element={
                        <Layout darkMode={darkMode}>
                          <FAQ darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/contact-us"
                      element={
                        <Layout darkMode={darkMode}>
                          <ContactUs darkMode={darkMode} />
                        </Layout>
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <AnimatedLoader />
              </div>
            )}
          </div>
        </HelmetProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
