import React from "react";
import { Box } from "@mui/system";

// Logo
import MainLogo from "assets/mainLogo.svg";
import MainLogoDark from "assets/mainLogoLight.svg";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// styles
import styles from "./AuthStyles.module.css";

import { useTranslation } from "react-i18next";

const AuthStatic = ({ darkMode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickeLogo = () => {
    navigate("/home");
  };

  return (
    <Box mx="auto">
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              cursor: "pointer",
            }}
            onClick={handleClickeLogo}
          >
            <img
              src={darkMode ? MainLogo : MainLogoDark}
              alt="Main Logo"
              width="75px"
              height="30px"
              style={{ display: "block", marginTop: 96 }}
            />
            <Typography
              fontWeight={600}
              textAlign="center"
              style={{ fontSize: 24, marginTop: 24 }}
            >
              Sign in to NFT Global Marketplace
            </Typography>
            {/* <Typography variant="h5" fontWeight={300} mt={2}>
              {t("NFT_MARKETPLACE")}
            </Typography> */}
          </Box>
          {/* <Box mt={10}>
            <Typography variant='h2' textAlign='center' fontWeight={500}>
              Collect & Sell <br /> Your{' '}
              {darkMode ? (
                <Typography
                  className={styles.heroGradientText}
                  component='span'
                  variant='h2'
                  fontWeight={600}
                  lineHeight={2}
                >
                  {t('AWESOME')} {' '}
                </Typography>
              ) : (
                <Typography
                  className={styles.heroGradientTextLight}
                  component='span'
                  variant='h2'
                  fontWeight={600}
                  lineHeight={2}
                >
                  {t('AWESOME')} {' '}
                </Typography>
              )}
              NFTs
            </Typography>
          </Box> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default AuthStatic;
