import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Typography,
  Divider,
  Stack,
  Avatar,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HiTemplate } from "react-icons/hi";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { chainIcons } from "configs/constant";
import { getDateTimeDifference } from "Utils";
import {
  CopyNftInfoDisplay,
  SetLikeBtnGroup,
} from "components/NftCard/NftCardDetails/Component";
import { defaultPngAvatar } from "configs/constant";

export const WebMetaInfo = ({
  darkMode,
  metaInfo,
  auctionStartDate,
  auctionEndDate,
  isLike,
  isBookmark,
  handleAddBookmark,
  handleAddFav,
}) => {
  const {
    name,
    collection_name,
    description,
    last_price,
    blockchain,
    uploaded,
    owner,
    likes,
    collection,
  } = metaInfo;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isLikeStatus, setIsLikeStatus] = useState(isLike);
  const [isBookmarkStatus, setIsBookmarkStatus] = useState(isLike);
  const dateRange = getDateTimeDifference(
    auctionStartDate,
    auctionEndDate
  ).days;
  useEffect(() => {
    setIsLikeStatus(isLike);
  }, [isLike]);
  useEffect(() => {
    setIsBookmarkStatus(isBookmark);
  }, [isBookmark]);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Modal
        sx={{ zIndex: 500000 }}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            bgcolor={darkMode ? "#171c26" : "#ffffff"}
            className={styles.modalStyleWeb}
          >
            <Typography
              className={styles.itemDetailsModalTitle}
              color="secondary"
              variant="h6"
              component="div"
            >
              <Typography component="span" color="secondary" sx={{ mt: 0.3 }}>
                <HiTemplate fontSize={"1.5rem"} />
              </Typography>
              <Typography variant="h6" component="span" mt={-0.2}>
                {t("ITEM_DETAILS")}
              </Typography>
            </Typography>
            <Typography
              className={styles.readMoreModalText}
              variant="body2"
              component="p"
              color="secondary"
              lineHeight={2}
              height={250}
              pr={2.5}
            >
              {description}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Box>
        <Typography
          variant="h6"
          component="h2"
          color="secondary.main"
          mb={4}
          fontWeight={600}
          fontSize="1.5rem"
        >
          {name} ({collection_name})
        </Typography>
        <CopyNftInfoDisplay
          darkMode={darkMode}
          label={"CONTRACT_ADDRESS"}
          copyValue={collection?.contract}
        >
          {collection?.contract?.substring(0, 6) +
            "..." +
            collection?.contract?.substring(
              collection?.contract?.length - 4,
              collection?.contract?.length
            )}
        </CopyNftInfoDisplay>
        <Typography
          variant="h6"
          component="p"
          color={darkMode ? "#FFFFFF" : "#121212"}
          mb={2}
          fontWeight={300}
        >
          Created By:{" "}
          <span style={{ fontWeight: "500", textDecoration: "underline" }}>
            {owner?.username}
          </span>
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color={darkMode ? "#FFFFFF" : "#121212"}
          mb={2}
          fontWeight={300}
        >
          Published By:{" "}
          <span style={{ fontWeight: "500", textDecoration: "underline" }}>
            NFT Global
          </span>
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color={darkMode ? "#FFFFFF" : "#121212"}
          mb={2}
          fontWeight={300}
        >
          Released:{" "}
          <span style={{ fontWeight: "500", textDecoration: "underline" }}>
            {`${uploaded?.days}d ${uploaded?.hours}hr ago`}
          </span>
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color={darkMode ? "#FFFFFF" : "#121212"}
          mb={2}
          fontWeight={300}
        >
          Collection:{" "}
          <span style={{ fontWeight: "500", textDecoration: "underline" }}>
            {collection_name}
          </span>
        </Typography>
        <Typography
          variant="body2"
          component="div"
          fontSize={17}
          lineHeight={1.5}
          color="secondary.main"
          mb={2}
          textAlign="justify"
        >
          {auctionStartDate &&
            `${t("SALE_ENDS")} ${auctionStartDate?.toDateString()} ${
              dateRange >= 1 ? "" : auctionStartDate?.toLocaleTimeString()
            }  to ${
              dateRange >= 1
                ? auctionEndDate?.toDateString()
                : auctionEndDate?.toLocaleTimeString()
            }`}
        </Typography>
        <>
          <Typography
            variant="h6"
            component="p"
            color={darkMode ? "#FFFFFF" : "#121212"}
            mb={2}
            fontWeight={500}
          >
            {t(`PRICE`)}
          </Typography>
          <Stack
            variant="h5"
            component="div"
            color="secondary.main"
            mb={3}
            fontWeight={500}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src={chainIcons[blockchain]?.logo}
              alt="Polygon Icon"
              mb={-0.5}
              width="25"
              height={"25"}
            />
            <Typography
              variant="body1"
              component="span"
              fontSize={"25px"}
              marginLeft={"10px"}
            >
              {" "}
              {" $"}
              {`${last_price}`}
            </Typography>
          </Stack>
        </>
        {/* <Divider className={styles.dividerBox} />
        <Box
          className={styles.singleNftCardInfo}
          sx={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Box className={styles.avatarBox}>
            <Box>
              <Avatar
                src={owner?.image ? owner?.image : defaultPngAvatar}
                alt={owner?.username}
              />
            </Box>
            <Stack direction="column">
              <Typography variant="caption" gutterBottom color="gray">
                {t("OWNER")}
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                color="secondary.main"
                sx={{
                  textTransform: "lowercase",
                  maxWidth: "90px",
                }}
                noWrap={true}
              >
                {owner?.username}
              </Typography>
            </Stack>
          </Box>
          <SetLikeBtnGroup
            darkMode={darkMode}
            uploaded={uploaded}
            likes={likes}
            isMobile={false}
            isLike={isLikeStatus}
            isBookmark={isBookmarkStatus}
            handleAddBookmark={handleAddBookmark}
            handleAddFav={handleAddFav}
          />
        </Box> */}
        <Divider className={styles.dividerBox} />
      </Box>
    </>
  );
};
