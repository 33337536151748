import image1 from "assets/campaign/1.webp";
import image2 from "assets/campaign/2.webp";
import image3 from "assets/campaign/3.webp";
import image4 from "assets/campaign/4.webp";
import image5 from "assets/campaign/5.webp";
import image6 from "assets/campaign/6.webp";
import image7 from "assets/campaign/7.webp";
import image8 from "assets/campaign/8.webp";
import image9 from "assets/campaign/9.webp";
import image10 from "assets/campaign/10.webp";
import image11 from "assets/campaign/11.webp";
import image12 from "assets/campaign/12.webp";
import image13 from "assets/campaign/13.webp";
import image14 from "assets/campaign/14.webp";
import image15 from "assets/campaign/15.webp";

export const images = [
  { index: 1, image: image1 },
  { index: 2, image: image2 },
  { index: 3, image: image3 },
  { index: 4, image: image4 },
  { index: 5, image: image5 },
  { index: 6, image: image6 },
  { index: 7, image: image7 },
  { index: 8, image: image8 },
  { index: 9, image: image9 },
  { index: 10, image: image10 },
  { index: 11, image: image11 },
  { index: 12, image: image12 },
  { index: 13, image: image13 },
  { index: 15, image: image15 },
  { index: 14, image: image14 },
];
